// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react'

// Components
import BannerWithVideoDefault from 'components/flex/BannerWithVideo/BannerWithVideoDefault'
import BannerWithVideoRight from 'components/flex/BannerWithVideo/BannerWithVideoRight'
import BannerWithVideoVacature from 'components/flex/BannerWithVideo/BannerWithVideoVacature'

interface BannerWithVideoProps {
  fields: any
  location?: any
}

interface BannerWithVideoProps {
  [key: string]: any
}

const BannerWithVideo: React.FC<CustomTextProps> = ({ fields, location = {} }) => {
  const customBannerWithVideo: BannerWithVideoProps = {
    default: BannerWithVideoDefault,
    video_right: BannerWithVideoRight,
    vacancy: BannerWithVideoVacature
  }

  if (!fields.styleview || !customBannerWithVideo[fields.styleview]) {
    return null
  }

  const Component = customBannerWithVideo[fields.styleview]

  return (
    <Component
      key={`${location ? location.pathname : ''}`}
      fields={fields}
      location={location}
    />
  )
}

export default BannerWithVideo
