import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

const StyledBannerWithVideo = styled.section<{ styleview: string }>`
  background-color: ${(props) => props.theme.color.face.main};

  ${(props) =>
    props.styleview === 'vacancy' &&
    css`
      @media (min-width: 992px) {
        padding-top: 100px !important;
      }

      @media (max-width: 991px) {
        padding-top: 75px !important;
      }
    `}

  ${(props) =>
    props.styleview === 'default' &&
    css`
      @media (min-width: 992px) {
        padding-top: 150px;
      }

      @media (max-width: 991px) {
        padding-top: 100px;
      }
    `}

    ${(props) =>
    props.styleview === 'video_right' &&
    css`
      @media (min-width: 992px) {
        padding-top: 150px;
      }

      @media (max-width: 991px) {
        padding-top: 100px;
      }
    `}

  @media (min-width: 992px) {
    padding-top: 150px;
  }

  @media (max-width: 991px) {
    padding-top: 100px;
  }
`

const BannerWithVideoContent = styled.video<{ styleview: string }>`
  object-fit: cover;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  width: 100%;

  @media (min-width: 992px) {
    height: 350px;
  }

  @media (max-width: 575px) {
    height: 250px;
  }

  ${(props) =>
    props.styleview === 'vacancy' &&
    css`
      @media (min-width: 992px) {
        height: 330px !important;
      }

      @media (max-width: 575px) {
        height: 230px !important;
      }
    `}
`

const BannerWithVideoDescription = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & p,
  & strong {
    color: ${({ theme }) => theme.color.text.light};
  }

  & h1 {
    img {
      width: 41px;
      height: 37px;
      object-fit: cover;
    }
  }

  @media (max-width: 575px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5 {
      font-size: ${(props) => props.theme.font.size.l};
      line-height: ${(props) => props.theme.font.size.l};
    }
  }

  @media (min-width: 992px) {
    & h1 {
      margin-top: 1.75rem;
      font-size: ${({ theme }) => theme.font.size[40]};
    }

    & h2 {
      margin-top: 1.75rem;
      font-size: ${({ theme }) => theme.font.size.l};
    }
  }

  @media (max-width: 991px) {
    font-weight: ${(props) => props.theme.font.weight.m};

    & p,
    & h1,
    & h2,
    & strong {
      color: ${(props) => props.theme.color.text.light};
    }
  }
`

const Mute = styled.button`
  background-color: ${({ theme }) => theme.color.face.light};
  border-radius: 50%;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 15px;
  bottom: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  font-size: 0;

  & svg {
    width: 20px;
    height: 20px;

    & path,
    & polygon,
    & line {
      fill: ${({ theme }) => theme.color.text.main};
    }
  }
`

const AutoPlay = styled.button`
  position: absolute;
  left: 10%;
  top: 10%;
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;

  & svg {
    width: 80px;
    height: 80px;
  }
`

interface BannerWithVideoVacatureProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_BannerWithVideo
  location: any
}

const BannerWithVideoVacature: React.FC<BannerWithVideoVacatureProps> = ({
  fields,
  location,
}) => {
  const urlParams = new URLSearchParams(location.search)
  const autoplay: boolean =
    !urlParams.get('autoplay') || urlParams.get('autoplay') !== 'no'
  const [play, setPlay] = React.useState<boolean>(false)
  const [isMuted, setMuted] = React.useState<boolean>(autoplay)
  const videoId: string = (fields?.videoUrl || fields?.video_url) as string

  React.useEffect(() => {
    if (!autoplay) {
      const video = document.getElementById(videoId) as HTMLVideoElement

      video.onloadeddata = () => {
        setTimeout(() => {
          video?.pause()
        }, 100)
      }
    }
  }, [autoplay])

  return (
    <StyledBannerWithVideo styleview={fields?.styleview || 'default'}>
      <div
        className={`container ${
          fields?.styleview === 'vacancy' ? 'py-lg-5 py-4' : ''
        }`}
      >
        <div className="row mt-4 mt-lg-0">
          <div
            className={`${
              fields?.styleview === 'vacancy'
                ? 'col-md-6 d-flex align-items-center order-2'
                : 'col-lg-5 pb-5 mb-5'
            } pr-lg-5 `}
          >
            <BannerWithVideoDescription content={fields.description} />
          </div>
          <div
            className={`${
              fields?.styleview === 'vacancy'
                ? 'col-md-6 mb-md-0 mb-4 order-md-3 order-1'
                : 'col-lg-7 pr-lg-5'
            } d-flex align-items-center`}
          >
            <div className="position-relative">
              <BannerWithVideoContent
                styleview={fields?.styleview || 'default'}
                controls={true}
                loop
                id={videoId}
              >
                <track kind="captions" />
                <source src={videoId} type="video/mp4" />
              </BannerWithVideoContent>

              <AutoPlay
                type="button"
                onClick={() => {
                  const video = document.getElementById(
                    videoId
                  ) as HTMLVideoElement

                  if (play) {
                    video.pause()
                  } else {
                    video.play()
                    video.muted = false
                    setMuted(false)
                  }

                  setPlay(!play)
                }}
              >
                {!play && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <polygon points="10 8 16 12 10 16 10 8" />
                  </svg>
                )}
                Afspelen
              </AutoPlay>
            </div>
          </div>
        </div>
      </div>
    </StyledBannerWithVideo>
  )
}

export default BannerWithVideoVacature
