import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

const StyledBannerWithVideo = styled.section`
  background-color: ${(props) => props.theme.color.face.main};

  @media (min-width: 992px) {
    padding-top: 150px;
  }

  @media (max-width: 991px) {
    padding-top: 100px;
  }
`

const BannerWithVideoTitle = styled.h1`
  color: ${(props) => props.theme.color.text.light};
  text-transform: uppercase;

  @media (min-width: 992px) {
    font-size: ${(props) => props.theme.font.size.xxl};
  }

  @media (max-width: 991px) {
    font-size: ${(props) => props.theme.font.size.xl};
  }
`

const BannerWithVideoContent = styled.video`
  object-fit: cover;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  width: 100%;

  @media (min-width: 992px) {
    height: 350px;
  }

  @media (max-width: 575px) {
    height: 250px;
  }
`

const BannerWithVideoDescription = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.light};
`

const Mute = styled.button`
  background-color: ${({ theme }) => theme.color.face.light};
  border-radius: 50%;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 15px;
  bottom: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  font-size: 0;

  & svg {
    width: 20px;
    height: 20px;

    & path,
    & polygon,
    & line {
      fill: ${({ theme }) => theme.color.text.main};
    }
  }
`

const AutoPlay = styled.button`
  position: absolute;
  left: 10%;
  top: 10%;
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;

  & svg {
    width: 80px;
    height: 80px;
  }
`

interface BannerWithVideoProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_BannerWithVideo
  location: any
}

const BannerWithVideoDefault: React.FC<BannerWithVideoProps> = ({
  fields,
  location,
}) => {
  const urlParams = new URLSearchParams(location.search)
  const autoplay: boolean =
    !urlParams.get('autoplay') || urlParams.get('autoplay') !== 'no'
  const [play, setPlay] = React.useState<boolean>(autoplay)
  const [isMuted, setMuted] = React.useState<boolean>(autoplay)
  const videoId: string = (fields?.videoUrl || fields?.video_url) as string

  React.useEffect(() => {
    if (!autoplay) {
      const video = document.getElementById(videoId) as HTMLVideoElement

      video.onloadeddata = () => {
        setTimeout(() => {
          video?.pause()
        }, 100)
      }
    }
  }, [autoplay])

  return (
    <StyledBannerWithVideo>
      <div className="container">
        <BannerWithVideoTitle className="mt-lg-3">
          {fields.title}
        </BannerWithVideoTitle>
        <div className="row mt-4 mt-lg-0">
          <div className="col-lg-7 d-flex align-items-center pb-5 pr-lg-5">
            <div className="position-relative">
              <BannerWithVideoContent
                autoPlay={autoplay}
                controls={true}
                muted={isMuted}
                playsInline
                id={videoId}
              >
                <track kind="captions" />
                <source src={videoId} type="video/mp4" />
              </BannerWithVideoContent>

              <AutoPlay
                type="button"
                onClick={() => {
                  const video = document.getElementById(
                    videoId
                  ) as HTMLVideoElement

                  if (play) {
                    video.pause()
                  } else {
                    video.play()
                    video.muted = false
                    setMuted(false)
                  }

                  setPlay(!play)
                }}
              >
                {!play && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <polygon points="10 8 16 12 10 16 10 8" />
                  </svg>
                )}
                Afspelen
              </AutoPlay>

              <Mute
                type="button"
                onClick={() => {
                  setMuted((prevState) => !prevState)
                }}
              >
                {isMuted ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-volume-x"
                  >
                    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5" />
                    <line x1="23" y1="9" x2="17" y2="15" />
                    <line x1="17" y1="9" x2="23" y2="15" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-volume-2"
                  >
                    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5" />
                    <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07" />
                  </svg>
                )}
                Dempen
              </Mute>
            </div>
          </div>
          <div className="col-lg-5 pt-lg-5 pb-5 mb-5">
            <BannerWithVideoDescription content={fields.description} />
          </div>
        </div>
      </div>
    </StyledBannerWithVideo>
  )
}

export default BannerWithVideoDefault
